import { Query } from '../../../types/api'
import { ReportingDataDTO } from '../../../types/reporting'
import { createQuery } from '../helpers'

const returnProps = `
  subscriptionsTotal
  subscriptionBreakdown {
    location
    plan
    subscriptionCount
  }
  subscriptionsAtCarpark
  customersTotal
  customersWithSubscriptionTotal
`

export const getReportingData: Query<ReportingDataDTO> = createQuery(`
  query getReportingData {
    getReportingData {
      ${returnProps}
    }
  }
`)
