import React from 'react'
import { useParams } from 'react-router-dom'

import { useApi, usePageTitle } from '../../app/hooks'
import { CenteredLoader, Invoice } from '../../components'
import { getSubscriptionInvoice } from '../../services/graphql/queries'

/**
 * Component which displays an invoice for a subscription
 * @return {ReactElement}
 */
export function SubscriptionInvoice ():React.ReactElement {
  const { id, billingDate } = useParams()

  usePageTitle('Subscription invoice')

  const query = useApi(getSubscriptionInvoice, {
    initialFetchParams: {
      billingDate: billingDate ?? '',
      subscriptionId: id ?? '',
    },
    initialFetch: true,
    displayErrorAlerts: true,
  })
  const response = query.getResponse()

  if (response.loading || !response.data) {
    return <CenteredLoader />
  }
  return <Invoice invoice={response.data} />
}
