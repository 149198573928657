import React, { ReactElement } from 'react'
import {
  Button, ButtonToolbar, Panel, Table
} from 'rsuite'

import {
  usePageTitle, useUser, useApi, useModal, useAlert
} from '../../app/hooks'
import {
  getOutstandingPayments, getPaymentMethod, retryOutstandingPayments
} from '../../services/graphql/queries'
import { TableData, Modal } from '../../components'
import { MissingPaymentMethodModal } from '../account/MissingPaymentMethodModal'
import { PaymentModal } from './PaymentModal'
import { AlertType, PaymentStatus } from '../../types/enums'
import type { RowData } from '../../types/table'
import type { Payment } from '../../types/payment'

/**
 * Outstanding payment list component
 * @return {ReactElement}
 */
function OutstandingPaymentList (): ReactElement|null {
  usePageTitle('Outstanding payments')

  const user = useUser()
  const pushAlert = useAlert()

  const paymentMethodQuery = useApi(getPaymentMethod)
  const fetchPaymentsQuery = useApi(getOutstandingPayments, {
    initialFetch: true,
    initialFetchParams: { userId: user.id },
  })
  const processPaymentsQuery = useApi(retryOutstandingPayments)

  const payModal = useModal()
  const noPaymentMethodModal = useModal()

  /**
   * Show the appropriate modal when attempting to make payment
   */
  const showModal = () => {
    paymentMethodQuery.sendRequest(
      { userId: user.id },
      (result) => {
        if (result) {
          payModal.show()
        } else {
          noPaymentMethodModal.show()
        }
        return result
      }
    )
  }

  /**
   * Show an alert message with the result of the payment retry
   * @param {Payment[]} payments - The retried payments with new statuses
   */
  const showPaymentResult = (payments: Payment[]) => {
    const success = payments.every(({ status }) => status === PaymentStatus.PAYMENT_ACCEPTED)
    pushAlert({
      type: success ? AlertType.SUCCESS : AlertType.ERROR,
      message: success
        ? 'Your payments are up to date. Your car park access will be restored soon.'
        : 'One or more payments could not be processed. Please check your payment method and try again.',
    })
  }

  /**
   * Process all outstanding payments
   */
  const processPayments = () => {
    processPaymentsQuery.sendRequest(
      { userId: user.id },
      (result) => {
        fetchPaymentsQuery.setData(() => (
          result.filter((item) => item.status !== PaymentStatus.PAYMENT_ACCEPTED)
        ))
        showPaymentResult(result)
        payModal.hide()
        return result
      }
    )
  }

  /**
   * Render the product description for a payment
   * @param {RowData} payment
   * @return {string}
   */
  const renderProduct = (payment: RowData): string => (
    `${payment.product.plan?.name ?? ''}
    (${payment.location.name})`
  )

  const payments = fetchPaymentsQuery.getResponse().data || []
  const paymentMethodLoading = paymentMethodQuery.getResponse().loading

  return (
    <>
      <Panel header={<h2>Outstanding payments</h2>}>
        <p>
          Any outstanding payments listed here will result in your Noover parking access
          being limted temorarily until the payments can be processed properly. We will attempt
          to process outstanding payments every day, however you can re-enable your parking access
          sooner by paying now.
        </p>
        <Table
          autoHeight
          data={payments}
          loading={fetchPaymentsQuery.getResponse().loading}
        >
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Payment due</Table.HeaderCell>
            <TableData dataKey="created" format="date" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Payment type</Table.HeaderCell>
            <TableData dataKey="type" format="tag" />
          </Table.Column>
          <Table.Column flexGrow={3}>
            <Table.HeaderCell>Product</Table.HeaderCell>
            <TableData dataKey="product" content={renderProduct} />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <TableData dataKey="outstanding" format="money" />
          </Table.Column>
        </Table>
      </Panel>
      {payments.length > 0 && (
        <Panel>
          <ButtonToolbar>
            <Button
              appearance="primary"
              loading={paymentMethodLoading}
              onClick={showModal}
            >
              Retry payments now
            </Button>
          </ButtonToolbar>
        </Panel>
      )}
      <Modal hook={noPaymentMethodModal}>
        <MissingPaymentMethodModal onClose={noPaymentMethodModal.hide} />
      </Modal>
      <Modal hook={payModal}>
        <PaymentModal
          payments={payments}
          onConfirm={processPayments}
          onClose={payModal.hide}
          loading={processPaymentsQuery.getResponse().loading}
        />
      </Modal>
    </>
  )
}

export {
  OutstandingPaymentList
}
