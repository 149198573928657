import React, { ReactElement, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
  useAlert, useApi, usePageTitle, useUser
} from '../../app/hooks'
import { CenteredLoader } from '../../components'
import { confirmCardVerification } from '../../services/graphql/queries'
import { AlertType, CardVerificationResult } from '../../types/enums'

/**
 * Component which users will land on upon finising their 3DS verification
 * @return {ReactElement}
 */
export function ThreeDSResult (): ReactElement {
  usePageTitle('Threeds verification')
  const [urlParams] = useSearchParams()
  const pushAlert = useAlert()
  const user = useUser()
  const navigate = useNavigate()

  // Whether the 3DS verification was successful
  const success = urlParams.get('success')

  // The associated verification id in the add payment method flow
  const id = urlParams.get('id')

  const confirmVerification = useApi(confirmCardVerification, {
    displayErrorAlerts: true,
  })
  const confirmResponse = confirmVerification.getResponse()

  useEffect(() => {
    if (!success || !id) {
      pushAlert({
        type: AlertType.ERROR,
        message: 'Error saving payment details. Please try again.',
      })
      navigate('/account')
    } else {
      confirmVerification.sendRequest({
        userId: user.id || '',
        verificationId: id || '',
        verificationResult: success === 'true'
          ? CardVerificationResult.SUCCESS : CardVerificationResult.FAILED,
      })
    }
  }, [])

  useEffect(() => {
    if (confirmResponse.data) {
      if (success === 'true') {
        pushAlert({
          type: AlertType.SUCCESS,
          message: 'Your payment method has been added',
        })
      } else {
        pushAlert({
          type: AlertType.ERROR,
          message: 'Error saving payment details. 3-D Secure verification failed.',
        })
      }
      navigate('/account')
    }
    if (confirmResponse.error) {
      pushAlert({
        type: AlertType.ERROR,
        message: 'Error saving payment details. Please try again.',
      })
      navigate('/account')
    }
  }, [confirmResponse])

  return <CenteredLoader />
}
