import { useState } from 'react'

/**
 * A hook for managing modal state
 * @return {Object} - The modal state and show/hide functions
 */
const useModal = () => {
  const [open, setOpen] = useState(false)

  return {
    open,
    show: () => setOpen(true),
    hide: () => setOpen(false),
  }
}

export {
  useModal
}
