import { createQuery } from '../helpers'
import type { UserDTO, GetUsersParams, GetUserParams } from '../../../types/user'
import type { PaginatedResponse, Query } from '../../../types/api'

const returnProps = `
  id
  givenName
  email
  familyName
  phoneNumber
  groups
  flags
`
const getUsers: Query<PaginatedResponse<UserDTO>, GetUsersParams> = createQuery(`
  query getUsers($group: String, $query: String, $flags: [Flag]) {
    getUsers(group: $group, query: $query, flags: $flags) {
      items {
        ${returnProps}
      }
      next
      limit
    }
  }
`)

const getUser: Query<UserDTO, GetUserParams> = createQuery(`
  query getUser($id: ID!) {
    getUser(id: $id) {
      ${returnProps}
    }
  }
`)

export {
  getUsers,
  getUser
}
