import React, { ReactElement } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Nav } from 'rsuite'
import { Icon } from '@rsuite/icons'
import {
  FaHome,
  FaBuilding,
  FaUser,
  FaCar,
  FaMagic,
  FaHistory
} from 'react-icons/fa'

import { useUser } from '../../app/hooks'
import { UserRole } from '../../types/enums'

/**
 * Menu items for the customer area
 * @return {ReactElement}
 */
function CustomerMenu (): ReactElement {
  const user = useUser()
  const isAdmin = user.hasRole(UserRole.ADMIN, UserRole.ORGANISATION)
  const path = useLocation().pathname
  return (
    <>
      <Nav.Item
        to="/"
        active={path === '/'}
        icon={<Icon as={FaHome} />}
        as={Link}
      >
        Dashboard
      </Nav.Item>

      <Nav.Item
        to="/vehicles"
        active={path.startsWith('/vehicles')}
        icon={<Icon as={FaCar} />}
        as={Link}
      >
        Vehicles
      </Nav.Item>
      <Nav.Item
        icon={<Icon as={FaMagic} />}
        to="/subscriptions"
        as={Link}
        active={path.startsWith('/subscriptions')}
      >
        Subscription
      </Nav.Item>
      <Nav.Item
        icon={<Icon as={FaHistory} />}
        to="/visits"
        as={Link}
        active={path.startsWith('/visits')}
      >
        History
      </Nav.Item>
      <hr />
      <Nav.Item
        to="/account"
        active={path.startsWith('/account')}
        icon={<Icon as={FaUser} />}
        as={Link}
      >
        My account
      </Nav.Item>
      {isAdmin && (
        <Nav.Item
          to="/admin/subscriptions"
          icon={<Icon as={FaBuilding} />}
          as={Link}
        >
          Admin account
        </Nav.Item>
      )}
    </>
  )
}

export {
  CustomerMenu
}
