import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import {
  Panel,
  IconButton,
  ButtonToolbar,
  Table,
  Tag,
  TagGroup,
  Form,
  ButtonGroup,
  Button
} from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FaPlus } from 'react-icons/fa'

import { usePageTitle, usePaginatedApi } from '../../app/hooks'
import { getAdminLocations } from '../../services/graphql/queries'
import { TableData } from '../../components'
import type { RowData } from '../../types/table'
import './LocationList.css'
import { locationIsCarpark } from '../../types/locationHelpers'

/**
 * location list component
 * @return {ReactElement}
 */
function LocationList (): ReactElement {
  usePageTitle('Locations')

  const api = usePaginatedApi({
    query: getAdminLocations,
    itemsPerPage: 50,
    fetchParams: {
      organisation: process.env.REACT_APP_ORGANISATION_ID || '',
    },
    queryOptions: {
      cleanUpOnDismount: true,
      displayErrorAlerts: true,
    },
  })

  /**
   * Render a name cell for a single location
   * @param {Location} location
   * @return {ReactElement}
   */
  const renderName = (location: RowData): ReactElement => (
    <Link to={location.id}>{location.name}</Link>
  )

  /**
   * Render a status cell for a single location
   * @param {Location} location
   * @return {ReactElement}
   */
  const renderStatus = (location: RowData): ReactElement => (
    <TagGroup>
      <Tag>{location.status}</Tag>
    </TagGroup>
  )

  /**
   * Render a occupancy cell for a single location
   * @param {Location} location
   * @return {ReactElement}
   */
  const renderOccupancy = (location: RowData): ReactElement => {
    if (!locationIsCarpark(location)) {
      return <span>-</span>
    }
    const { total, available } = location.capacity
    const used = total - available
    const occupancy = total > 0 ? (used / total) : 1
    return (
      <span>
        {`${Math.round(occupancy * 100)}% (${used}/${total})`}
      </span>
    )
  }

  return (
    <Panel
      header={(
        <>
          <h2>Car parks</h2>
          <ButtonToolbar>
            <IconButton
              appearance="subtle"
              icon={<Icon as={FaPlus} />}
              as={Link}
              to="new"
            />
          </ButtonToolbar>
        </>
      )}
      className="location-list"
    >
      <Table data={api.currentPage || []} loading={api.loading} autoHeight>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Location name</Table.HeaderCell>
          <TableData dataKey="name" content={renderName} />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <TableData dataKey="status" content={renderStatus} />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>
            Occupancy
            <Form.HelpText tooltip>
              The proportion of parking spaces that are currently occupied
            </Form.HelpText>
          </Table.HeaderCell>
          <TableData dataKey="occupancy" content={renderOccupancy} />
        </Table.Column>
      </Table>
      <div className="pagination">
        <ButtonGroup>
          <Button disabled={!api.prev} onClick={() => api.prev?.()}>
            Prev
          </Button>
          <Button disabled={!api.next} onClick={() => api.next?.()}>
            Next
          </Button>
        </ButtonGroup>
      </div>
    </Panel>
  )
}

export {
  LocationList
}
