import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Loader, Panel
} from 'rsuite'

import { useApi, useFormState, usePageTitle } from '../../app/hooks'
import { PanelHeader } from '../../components'
import { editSubscription, getSubscriptionById } from '../../services/graphql/queries'
import { Plan } from '../../types/pricing'
import { pricingIsPlan } from '../../types/pricingHelpers'
import { EditSubscriptionFormValue } from '../../types/subscription'
import { EditSubscriptionForm } from '../../forms/EditSubscriptionForm'

/**
 * Panel where admins can edit a user's subscription
 * @return {React.ReactElement}
 */
function AdminEditSubscription () {
  usePageTitle('Edit subscription')
  const { id: subscriptionId } = useParams()
  const subscriptionQuery = useApi(getSubscriptionById, {
    initialFetch: true,
    initialFetchParams: {
      subscriptionId: subscriptionId ?? 'unknown id',
    },
    displayErrorAlerts: true,
    cleanUpOnDismount: true,
  })

  const saveQuery = useApi(editSubscription)
  const navigate = useNavigate()
  const saveResponse = saveQuery.getResponse()
  const subscriptionResponse = subscriptionQuery.getResponse()
  const currentSubscription = subscriptionResponse.data
  const pricings:Plan[] = currentSubscription?.location
    .pricing.filter((e) => pricingIsPlan(e)) as Plan[] ?? []

  const initialValue: EditSubscriptionFormValue = {
    vehicles: 0,
    plan: '',
  }
  const [formData, setFormData] = useFormState(initialValue)

  const pricingPlansAvailable = pricings.map((e) => ({
    label: `${e.name} ($${e.price})`,
    value: e.id,
  }))

  useEffect(() => {
    if (currentSubscription) {
      setFormData({
        ...formData,
        vehicles: currentSubscription.vehicles,
        plan: currentSubscription.plan.id,
      })
    }
  }, [subscriptionResponse.data])

  const formId = 'admin-edit-subscription-form'

  /**
   * Cb to call to route back to the admin subscirptions page
   */
  const routeBack = () => {
    navigate('/admin/subscriptions')
  }

  const isSaveLoading = saveResponse.loading
  const isFetchLoading = subscriptionResponse.loading

  /**
   * Cb to run when users submit the edits of the subscription
   */
  const onSubmit = () => {
    saveQuery.sendRequest({
      id: subscriptionId || 'unknown subscription',
      vehicles: formData.vehicles,
      plan: formData.plan,
    })
  }

  useEffect(() => {
    if (saveResponse.data) {
      routeBack()
    }
  }, [saveResponse.data])

  return (
    <div className="admin-edit-subscription">
      <Panel header={(
        <PanelHeader
          header="Edit customer subscription"
          editing
          formId={formId}
          onCancel={routeBack}
          loading={isSaveLoading}
        />
      )}
      >
        {isFetchLoading ? <Loader />
          : (
            <EditSubscriptionForm
              formId={formId}
              error={saveResponse.error}
              formValue={formData}
              onChange={setFormData}
              onSubmit={onSubmit}
              pricingPlans={pricingPlansAvailable}
            />
          )}
      </Panel>
    </div>
  )
}

export {
  AdminEditSubscription
}
