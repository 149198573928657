import React, { useState, ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { Panel } from 'rsuite'

import { useCustomerLocations } from '../../app/hooks/customerLocation'
import { pricingIsPlan } from '../../types/pricingHelpers'
import { PanelHeader } from '../../components'
import { LocationWithDefaultPrice } from '../../types/location'
import { CustomerSubscriptionLocationTable } from './CustomerSubscriptionLocationTable'
import { CustomerConfirmSubscription } from './CustomerConfirmSubscription'
import { usePageTitle } from '../../app/hooks'

import './CustomerCreateSubscription.css'

/**
 * Page for  customer to create subscriptions.
 * They first select a location then confirm with number of vehicles.
 * @return {ReactElement}
 */
function CustomerCreateSubscription (): ReactElement {
  const { locations } = useCustomerLocations({
    displayErrorAlerts: true,
  })
  const tableData: LocationWithDefaultPrice[] = []
  const [chosenLocationId, setChosenLocationId] = useState<string|null>(null)
  locations.data?.forEach((e) => {
    const defaultPlan = e.pricing[0] ?? null
    if (pricingIsPlan(defaultPlan)) {
      tableData.push({ ...e, pricing: defaultPlan })
    }
  })

  usePageTitle('New Subscription')

  const chosenLocation = tableData.find((e) => e.id === chosenLocationId ?? '') ?? null

  /**
   *  Cb when user has chosen a location from the table.
   *  @param {String} locationId - The id of the location chosen
   */
  const chooseLocationCb = (locationId:string) => {
    setChosenLocationId(locationId)
  }

  const navigate = useNavigate()

  /**
   * Cb when user clicks the close button in the header
   */
  const cancelCreateNewSubscription = () => {
    navigate('/subscriptions')
  }

  return (
    <div className="create-subscription">
      <Panel header={(
        <PanelHeader
          header="Create new subscription"
          editing
          onCancel={cancelCreateNewSubscription}
        />
      )}
      >
        { chosenLocation === null
          ? (
            <CustomerSubscriptionLocationTable
              data={tableData}
              loading={locations.loading}
              onSelectCb={chooseLocationCb}
            />
          )
          : <CustomerConfirmSubscription location={chosenLocation} />}
      </Panel>
    </div>
  )
}

export {
  CustomerCreateSubscription
}
