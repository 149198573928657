import React, { ReactElement, useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import {
  Panel, Table
} from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FaPlus } from 'react-icons/fa'

import { useApi, useModal } from '../../app/hooks'
import { getLocationTariffs, getLocation, removeTariff } from '../../services/graphql/queries'
import {
  ConfirmModal, TableData, ActionMenu, Modal, PanelHeader
} from '../../components'
import { deleteFromPaginatedWithId } from '../../helpers'
import type { RowData } from '../../types/table'
import type { TariffConfig } from '../../types/tariff'

const { Column, HeaderCell, Cell } = Table

/**
 * Tariff list component
 * @return {ReactElement}
 */
function LocationTariffList (): ReactElement {
  const navigate = useNavigate()

  const { locationId } = useParams()
  const tariffQuery = useApi(getLocationTariffs)
  const locationQuery = useApi(getLocation)
  const deleteQuery = useApi(removeTariff)

  const tariffData = tariffQuery.getResponse()
  const locationData = locationQuery.getResponse()
  const modal = useModal()
  const [tariffForRemoval, setTariffForRemoval] = useState<TariffConfig>()

  useEffect(() => {
    if (locationId) {
      tariffQuery.sendRequest({ locationId })
      locationQuery.sendRequest({ locationId })
    }
    return () => {
      tariffQuery.cleanup()
      locationQuery.cleanup()
      deleteQuery.cleanup()
    }
  }, [])

  /**
   * Show the gate removal confirmation modal
   * @param {RowData} gate - The gate to remove
   */
  const confirmRemoval = (tariff: RowData) => {
    setTariffForRemoval(tariff as TariffConfig)
    modal.show()
  }
  /**
   * Remove the gate from the location and reload the gate list
   */
  const remove = () => {
    if (tariffForRemoval) {
      deleteQuery.sendRequest({
        tariffId: tariffForRemoval.id,
      }, () => {
        tariffQuery.setData((oldData) => deleteFromPaginatedWithId(oldData, tariffForRemoval.id))
      })
    }
  }

  /**
   * Dropdown menu for a row in the gates list table
   * @param {RowData} gate - The gate object of the row
   * @return {ReactElement}
   */
  const renderActions = (tariff: RowData): ReactElement => {
    const actions = [
      { label: 'Delete', action: () => confirmRemoval(tariff) },
    ]
    return (
      <ActionMenu actions={actions} />
    )
  }

  const tariffs = (locationData.data && tariffData.data?.items) || []

  return (
    <>
      <Panel
        className="tariff-list"
        header={(
          <PanelHeader
            header="Tariffs"
            onEdit={() => navigate('tariffs/new')}
            icon={<Icon as={FaPlus} />}
          />
        )}
      >
        <Table
          autoHeight
          data={tariffs}
          loading={tariffData.loading}
        >
          <Column flexGrow={1}>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="name">
              {(rowData) => (
                <Link to={`tariffs/${rowData.id}`}>
                  {rowData.name}
                </Link>
              )}
            </Cell>
          </Column>
          <Column flexGrow={0.5}>
            <HeaderCell>Actions</HeaderCell>
            <TableData dataKey="action" content={renderActions} />
          </Column>
        </Table>
      </Panel>
      <Modal hook={modal}>
        <ConfirmModal
          message={`The ${tariffForRemoval?.name} tariff will be removed from this location.`}
          onConfirm={remove}
          onClose={modal.hide}
        />
      </Modal>
    </>
  )
}

export {
  LocationTariffList
}
