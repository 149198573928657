/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useEffect } from 'react'

import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { Panel, Loader } from 'rsuite'

import {
  useApi, useApiGroup, useFormState, usePageTitle
} from '../../app/hooks'
import { getTariffConfig } from '../../services/graphql/queries'
import { TariffForm } from '../../forms/TariffForms/TariffForm'
import { PanelHeader } from '../../components'
import { createTariff, updateTariff } from '../../services/graphql/queries/tariffs'
import { CreateTariffParams } from '../../types/tariff'
import { NotFound } from '../error-pages/NotFound'

/**
 * Component to create Gate
 * @returns {ReactElement}
 */
function LocationTariffDetails () : ReactElement {
  const navigate = useNavigate()
  const { locationId, tariffId } = useParams()
  const api = useApiGroup({
    fetch: useApi(getTariffConfig),
    create: useApi(createTariff),
    update: useApi(updateTariff),
  })

  const fetchResponse = api.requests.fetch.getResponse()
  const saveResponse = tariffId
    ? api.requests.update.getResponse()
    : api.requests.create.getResponse()

  const initialValues = tariffId ? fetchResponse.data : { name: '' }
  const [formData, setField] = useFormState(initialValues)

  useEffect(() => {
    if (tariffId) {
      api.requests.fetch.sendRequest({ tariffId })
    }
    return api.cleanup
  }, [])

  const pageTitle = tariffId ? 'Edit tariff' : 'New tariff'
  usePageTitle(pageTitle)

  if (fetchResponse.success && !fetchResponse.data) {
    return <NotFound />
  }

  if (saveResponse.success) {
    return <Navigate to={`/admin/locations/${locationId}`} />
  }

  /**
   * Create or update a gate using the API
   */
  const save = (data: any) => {
    const tariff = { ...data, location: locationId } as CreateTariffParams
    if (tariffId) {
      api.requests.update.sendRequest({ tariffId, ...tariff })
    } else {
      api.requests.create.sendRequest(tariff)
    }
  }

  /**
   * Return to the location list screen
   */
  const cancel = () => {
    navigate(`/admin/locations/${locationId}`)
  }

  const formId = 'tariff-details-form'

  return (
    <Panel
      header={(
        <PanelHeader
          loading={saveResponse.loading}
          editing
          formId={formId}
          onCancel={cancel}
          header={pageTitle}
        />
      )}
    >
      {api.requests.fetch.getResponse().loading
        ? <Loader center content="Loading..." />
        : (
          <TariffForm
            formId={formId}
            formValue={formData}
            onChange={setField}
            onSubmit={save}
            error={saveResponse.error}
            mode={tariffId ? 'edit' : 'new'}
          />
        )}
    </Panel>
  )
}

export {
  LocationTariffDetails
}
