import {
  CreateSubscriptionParams, GetAdminSubscriptionParams, GetSubscriptionByIdParams,
  ReviewSubscriptionParams, CancelSubscriptionParams, SubscriptionDTO,
  EditSubscriptionParams, SubscriptionDatesBilledDTO, GetSubscriptionDatesBilledParams,
  GetSubscriptionInvoiceParams
} from '../../../types/subscription'
import { createQuery } from '../helpers'
import type { PaginatedResponse, Query, RequestPaginationOptions } from '../../../types/api'
import { InvoiceDTO } from '../../../types/payment'

const returnProps = `
  id
  user {
    id
    givenName
    familyName
    phoneNumber
    email
    groups
    flags
  }
  plan {
    id
    name
    price
  }
  location {
    id
    name
    description
    address
    timezone
    status
    type
    controllers
    organisation {
      id
      name
    }
    geolocation {
      lat
      lon
    }
    featureDetails {
      key
      enabled
      name
    }
    pricing {
      id
      name
      organisation
      type
      ... on Plan {
        price
      }
    }
  }
  vehicles
  requestDate
  startDate
  endDate
  status
`

const createSubscription: Query<SubscriptionDTO, CreateSubscriptionParams> = createQuery(`
  mutation createSubscription($locationId: ID!, $numVehicles: Int!, $user: ID!, $plan: ID) {
    createSubscription(location: $locationId, vehicles: $numVehicles, user: $user, plan: $plan) {
      ${returnProps}
    }
  }
`)

const getSubscriptionById: Query<SubscriptionDTO, GetSubscriptionByIdParams> = createQuery(`
  query getSubscription($subscriptionId: ID!) {
    getSubscription(id: $subscriptionId) {
      ${returnProps}
    }
  }
`)

const getSubscriptions: Query<PaginatedResponse<
SubscriptionDTO>,
{ userId: string } & RequestPaginationOptions
> = createQuery(`
  query getUserSubscriptions($userId: ID!) {
    getUserSubscriptions(user: $userId) {
      items {
        ${returnProps}
      }
      next
      limit
    }
  }
`)

const getAdminSubscriptions: Query<
PaginatedResponse<SubscriptionDTO>,
GetAdminSubscriptionParams
> = createQuery(`
  query getAdminSubscriptions($params: AdminSubscriptionSearchParams, $limit: Int, $next: String) {
    getAdminSubscriptions(params: $params, limit: $limit, next: $next) {
      items {
        ${returnProps}
      }
      next
      limit
    }
  }
`)

const reviewSubscription: Query<SubscriptionDTO, ReviewSubscriptionParams> = createQuery(`
  mutation reviewSubscription($subscriptionId: ID!, $action: ReviewAction!) {
    reviewSubscription(id: $subscriptionId, action: $action) {
      ${returnProps}
    }
  }
`)

const cancelSubscription: Query<SubscriptionDTO, CancelSubscriptionParams> = createQuery(`
  mutation cancelSubscription($subscriptionId: ID!) {
    cancelSubscription(id: $subscriptionId) {
      ${returnProps}
    }
  }
`)

const getSubscriptionEndDate: Query<string, GetSubscriptionByIdParams> = createQuery(`
  query getSubscriptionEndDate($subscriptionId: ID!) {
    getSubscriptionEndDate(id: $subscriptionId)
  }
`)

const editSubscription: Query<SubscriptionDTO, EditSubscriptionParams> = createQuery(`
  mutation editSubscription($id: ID!, $vehicles: Int, $plan: ID) {
    editSubscription(id: $id, vehicles: $vehicles, plan: $plan) {
      ${returnProps}
    }
  }
`)

const getSubscriptionDatesBilledQuery = `
  query getSubscriptionDatesBilled($subscriptionId: ID!) {
    getSubscriptionDatesBilled(subscription: $subscriptionId)
  }
`

const getSubscriptionDatesBilled:
Query<
SubscriptionDatesBilledDTO,
GetSubscriptionDatesBilledParams
> = createQuery(getSubscriptionDatesBilledQuery)

const getSubscriptionInvoiceQuery = `
  query getSubscriptionInvoice($billingDate: String!, $subscriptionId: ID!) {
    getSubscriptionInvoice(billingDate: $billingDate, subscription: $subscriptionId) {
      total
      items {
        description
        quantity
        unitPrice
        gst
        total
        outstanding
      }
      outstanding
      amountPaid
      billingDate
      isCancelled
    }
  }
`
const getSubscriptionInvoice: Query<InvoiceDTO, GetSubscriptionInvoiceParams> = createQuery(
  getSubscriptionInvoiceQuery
)

export {
  getSubscriptionInvoice,
  getSubscriptionDatesBilled,
  editSubscription,
  getSubscriptionById,
  getSubscriptions,
  reviewSubscription,
  getAdminSubscriptions,
  createSubscription,
  cancelSubscription,
  getSubscriptionEndDate
}
