import React, { ReactElement, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, ButtonToolbar, Panel } from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FaPlus, FaTrash } from 'react-icons/fa'

import {
  useApi, useFormState, useUser, useModal, useAlert
} from '../../app/hooks'
import {
  getPaymentMethod, setPaymentMethod, removePaymentMethod, updateCardVerificationSession
} from '../../services/graphql/queries'
import * as paymentService from '../../services/payment/client'
import * as graphqlService from '../../services/graphql/client'
import {
  DefinitionList, PanelHeader, Modal, ConfirmModal
} from '../../components'
import { PaymentDetailsForm } from '../../forms/PaymentDetailsForm'
import { CardDetails } from '../../types/payment-method'
import './PaymentDetails.css'
import { AlertType } from '../../types/enums'

/**
 * Payment details component
 * @return {ReactElement}
 */
function PaymentDetails (): ReactElement {
  const user = useUser()
  const pushAlert = useAlert()

  const paymentMethodQuery = useApi(getPaymentMethod)
  const updateQuery = useApi(setPaymentMethod)
  const removeQuery = useApi(removePaymentMethod)

  const fetchResponse = paymentMethodQuery.getResponse()
  const saveResponse = updateQuery.getResponse()
  const removeResponse = removeQuery.getResponse()

  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)

  const modal = useModal()
  const [formData, setField, resetForm] = useFormState({
    cardNumber: '',
    cardHolderName: '',
    expiry: null,
    verificationCode: '',
  })

  useEffect(() => {
    paymentMethodQuery.sendRequest({ userId: user.id })
    return () => {
      paymentMethodQuery.cleanup()
      updateQuery.cleanup()
      removeQuery.cleanup()
    }
  }, [])

  // Data comes in with YYYY-MM
  // We want MM/yy
  const expiry = fetchResponse.data?.expiry.split('-').reverse() ?? []
  if (expiry[1]?.length > 2) {
    expiry[1] = expiry[1].substring(2)
  }

  const paymentData = !fetchResponse.loading && fetchResponse.data && {
    'Card number': `---- ---- ---- ${fetchResponse.data.last4}`,
    Expiry: expiry.join('/'),
  }

  /**
   * Remove the current payment details
   */
  const remove = () => {
    paymentMethodQuery.reset()
    removeQuery.sendRequest({ userId: user.id })
    resetForm()
  }

  /**
   * Enter edit mode or remove the payment method, if set
   */
  const editOrRemove = () => {
    if (!fetchResponse.loading && fetchResponse.data) {
      modal.show()
    } else {
      setEditing(true)
    }
  }

  /**
   * Exit edit mode
   */
  const editOff = () => setEditing(false)

  /**
   * Save the payment details
   */
  const save = async () => {
    setSaving(true)
    updateQuery.reset()

    const cardDetails = {
      ...formData,
      cardNumber: formData.cardNumber.replace(/ /g, ''),
    } as unknown as CardDetails

    try {
      const tokeniseCardResult = await paymentService.tokeniseCardDetails(user.id, cardDetails)
      if (tokeniseCardResult.type === 'CardTokenisationResultWith3DS') {
        await updateCardVerificationSession({
          userId: user.id,
          sessionId: tokeniseCardResult.sessionId,
          verificationId: tokeniseCardResult.verificationId,
          last4: cardDetails.cardNumber.slice(-4),
        })(graphqlService.run)
        window.location.href = tokeniseCardResult.threeDSRedirect
      } else {
        const payload = {
          userId: user.id,
          sessionId: tokeniseCardResult.sessionId,
          last4: cardDetails.cardNumber.slice(-4),
        }
        updateQuery.sendRequest(payload, (data) => {
          paymentMethodQuery.setData(() => data)
          return data
        })
      }
    } catch (error: any) {
      setSaving(false)
      pushAlert({
        type: AlertType.ERROR,
        message: 'Error saving payment details. Please try again.',
      })
    }
  }

  useEffect(() => {
    if (saving && saveResponse.sent && !saveResponse.loading) {
      setSaving(false)
    }
    if (editing && saveResponse.success) {
      editOff()
    }
  }, [updateQuery.getResponse().data])

  const loading = fetchResponse.loading || removeResponse.loading || saveResponse.loading || saving
  const paymentDetails = paymentData || loading
    ? <DefinitionList data={paymentData || {}} loading={loading} />
    : <p>No payment method</p>

  const editIcon = <Icon as={paymentData ? FaTrash : FaPlus} />
  const formId = 'payment-details-form'
  const shouldDisplayAddButton = !paymentData && !editing && !loading

  return (
    <>
      <Panel
        header={paymentData || editing ? (
          <PanelHeader
            loading={saving}
            editing={editing}
            onEdit={editOrRemove}
            formId={formId}
            onCancel={editOff}
            header="Payment method"
            icon={editIcon}
          />
        ) : (<h2>Payment method</h2>)}
      >
        {!editing && (
          paymentDetails
        )}
        { editing && (
          <PaymentDetailsForm
            loading={saving}
            formId={formId}
            formValue={formData}
            onChange={setField}
            onSubmit={save}
            error={saveResponse.error}
          />
        )}
      </Panel>
      {!loading && !editing && (
        <Panel>
          <ButtonToolbar>
            {shouldDisplayAddButton && (
              <Button
                onClick={editOrRemove}
                className="addpaymentbutton"
                appearance="primary"
              >
                Add payment method
              </Button>
            )}
            <Button
              as={Link}
              to="/payments/outstanding"
              appearance={paymentData ? 'primary' : 'default'}
            >
              View outstanding payments
            </Button>
          </ButtonToolbar>
        </Panel>
      )}
      <Modal hook={modal}>
        <ConfirmModal
          message={`Your payment method will be removed from your account, and you will
            no longer be able to park with Noover. To regain access, you can add a new
            payment method at any time.`}
          onConfirm={remove}
          onClose={modal.hide}
        />
      </Modal>
    </>
  )
}

export {
  PaymentDetails
}
