import React from 'react'

import { Form, InputPicker } from 'rsuite'

import type { FormProps } from '../types/form'
import * as schemas from './validation/schemas'
import { Validator } from './validation/validator'
import * as options from '../features/locations/LocationOptions'

import { ValidatedForm } from '../components'

/**
 * Location form component
 * @param {Props} props
 * @return {ReactElement}
 */
function LocationGateForm (props: FormProps) {
  const {
    formValue,
    onChange,
    onSubmit,
    error,
    formId,
  } = props

  const validator = new Validator({
    name: {
      model: schemas.required('Please enter a name for the gate'),
    },
    lane: {
      model: schemas.required('Please enter a Lane id'),
    },
  })

  return (
    <ValidatedForm
      fluid
      id={formId}
      validator={validator}
      formValue={formValue}
      onChange={onChange}
      onSubmit={onSubmit}
      error={error}
    >
      <Form.Group controlId="name">
        <Form.ControlLabel>Name</Form.ControlLabel>
        <Form.Control name="name" type="text" />
      </Form.Group>
      <Form.Group controlId="description">
        <Form.ControlLabel>Description</Form.ControlLabel>
        <Form.Control name="description" type="text" />
      </Form.Group>
      <Form.Group controlId="type">
        <Form.ControlLabel>Type</Form.ControlLabel>
        <Form.Control name="type" accepter={InputPicker} data={options.gatetypes} />
      </Form.Group>
      <Form.Group controlId="lane">
        <Form.ControlLabel>Lane ID</Form.ControlLabel>
        <Form.Control name="lane" type="text" />
      </Form.Group>
    </ValidatedForm>
  )
}

export {
  LocationGateForm
}
