import { createQuery } from '../helpers'
import type {
  AccessList, CreateAccessListParams,
  GetLocationAccessListsParams,
  UpdateAccessListParams
} from '../../../types/access-list'
import type { PaginatedResponse, Query } from '../../../types/api'

/**
 * @var string
 * The properties to get back in any AccessList response
 */
const returnProps = `
  type
  id
  name
  items
  descriptions
`

const getAccessList: Query<AccessList, { accessListId: string }> = createQuery(`
  query getAccessList($accessListId: ID!) {
    getAccessList(id: $accessListId) { ${returnProps} }
  }
`)

const getLocationAccessLists: Query<
PaginatedResponse<AccessList>,
GetLocationAccessListsParams
> = createQuery(`
  query getLocationAccessLists($location: ID!) {
    getLocationAccessLists(location: $location) { 
      items {
        ${returnProps} 
      }
      next
      limit
    }
  }
`)

const createAccessList: Query<AccessList, CreateAccessListParams> = createQuery(`
  mutation addAccessList(
    $name: String!,
    $location: ID!, 
    $type: ApiEnabledAccessListType!
    $items: [String]!
    $descriptions: [String]
  ) {
    addAccessList(
      name: $name,
      location: $location,
      type: $type
      items: $items,
      descriptions:$descriptions
    ) { ${returnProps} }
  }
`)

const updateAccessList: Query<AccessList, UpdateAccessListParams> = createQuery(`
  mutation editAccessList(
    $id: ID!,
    $name: String!,
    $location: ID!, 
    $type: ApiEnabledAccessListType!
    $items: [String]!
    $descriptions: [String]
  ) {
    editAccessList(
      id: $id,
      name: $name,
      location: $location,
      type: $type,
      items: $items,
      descriptions:$descriptions
    ) { ${returnProps} }
  }
`)

const removeAccessList: Query<void, { accessListId: string }> = createQuery(`
  mutation removeAccessList($accessListId: ID!) {
    removeAccessList(id: $accessListId)
  }
`)

export {
  getAccessList,
  getLocationAccessLists,
  createAccessList,
  updateAccessList,
  removeAccessList
}
