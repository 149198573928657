import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import { dateFormat } from '../config'

import { UserVisitsTableRow, Visit, VisitDTO } from './visits'

dayjs.extend(duration)

/**
 * Converts a VisitDTO to a Visit object by determining its variant
 * @param {VisitDTO} visitDto - The object to convert
 * @return {Visit}
 */
export function visitDtoToVisit (visitDto:VisitDTO): Visit {
  if (visitDto.payment) {
    const { payment } = visitDto
    return {
      type: 'VisitWithPayment',
      ...visitDto,
      payment,
    }
  }
  return {
    type: 'SubscriptionVisit',
    ...visitDto,
  }
}

/** Get suffix for a unit, adds an 's' if it is plural */
const getSuffix = (x:number) => (x > 1 ? 's' : '')

/**
 * Converts a visit object into the desired output to display in the users
 * visit table.
 * @param {Visit} visit         - The visit to convert
 */
export function getUserVisitsTableRow (visit: Visit): UserVisitsTableRow {
  let totalPrice = '-'
  let fee = '-'
  let durationStr = ''
  if (visit.type === 'VisitWithPayment') {
    totalPrice = (
      Number(visit.payment.amount) ?? 0
      + Number(visit.payment.surcharge) ?? 0
    ).toString()
    fee = (Number(visit.payment.amount) ?? 0).toString()
  }

  const entry = dayjs(visit.entry.time)
  const exit = dayjs(visit.exit.time)

  const timeFormatting = 'h:mm A'

  const date = entry.format(dateFormat)
  const entryTime = entry.format(timeFormatting)

  // If exit is on a different day to entry, we display the exit date as well
  const isSameDay = entry.startOf('day').isSame(exit.startOf('day'))
  const exitTime = exit.format(isSameDay ? timeFormatting : `${timeFormatting} (${dateFormat})`)

  // The iso duration str in visit.duration has a maximum unit of hours and it
  // messes up dayjs's units. So we are creating a dayjs duration then expressing that as milliseconds
  // then reparsing it to get the correct units
  let visitDuration = dayjs.duration(visit.duration)
  visitDuration = dayjs.duration(visitDuration.asMilliseconds())

  const days = Math.floor(visitDuration.asDays())
  const dayStr = days ? `${days} day${getSuffix(days)}, ` : ''
  const hrStr = visitDuration.hours().toString()
  const minutes = visitDuration.seconds() ? visitDuration.minutes() + 1 : visitDuration.minutes()
  const minuteStr = minutes < 10 ? `0${minutes.toString()}` : minutes.toString()

  durationStr = `${dayStr}${hrStr}:${minuteStr}`

  return {
    carparkName: visit.location.name,
    vehicleRego: visit.vehicle.rego,
    date,
    entryTime,
    exitTime,
    duration: durationStr,
    accessType: visit.access,
    totalPrice,
    fee,
  }
}
