import React, { ReactElement } from 'react'
import { Form, MaskedInput, DatePicker } from 'rsuite'

import { ValidatedForm } from '../components'
import * as schemas from './validation/schemas'
import * as masks from './validation/masks'
import { Validator } from './validation/validator'
import type { FormProps } from '../types/form'

/**
 * Payment details form component
 * @return {ReactElement}
 */
function PaymentDetailsForm (props: FormProps): ReactElement {
  const {
    formValue, onChange, onSubmit, error, formId,
  } = props

  const validator = new Validator({
    cardNumber: {
      model: schemas.cardNumber('Please provide your 16-digit card number'),
    },
    cardHolderName: {
      model: schemas.required('Please provide the name of the card holder'),
    },
    expiry: {
      model: schemas.expiryDate('Please provide the card expiry date'),
    },
    verificationCode: {
      model: schemas.cardVerification('Please provide the card verification number'),
    },
  })

  return (
    <ValidatedForm
      fluid
      id={formId}
      validator={validator}
      formValue={formValue}
      onChange={onChange}
      onSubmit={onSubmit}
      error={error}
    >
      <Form.Group controlId="cardNumber">
        <Form.ControlLabel>Card number</Form.ControlLabel>
        <Form.Control
          name="cardNumber"
          accepter={MaskedInput}
          mask={masks.cardNumber}
          guide={false}
          placeholder="0000 0000 0000 0000"
          autoComplete="cc-number"
        />
      </Form.Group>
      <Form.Group controlId="cardHolderName">
        <Form.ControlLabel>Card holder name</Form.ControlLabel>
        <Form.Control
          name="cardHolderName"
          type="input"
          autoComplete="cc-name"
        />
      </Form.Group>
      <Form.Group controlId="expiry">
        <Form.ControlLabel>Expiry date</Form.ControlLabel>
        <Form.Control
          name="expiry"
          accepter={DatePicker}
          oneTap
          format="MM/yy"
          preventOverflow
          autoComplete="cc-exp"
        />
      </Form.Group>
      <Form.Group controlId="verificationCode">
        <Form.ControlLabel>Verification number</Form.ControlLabel>
        <Form.HelpText tooltip>This is the three-digit number on the back of the card</Form.HelpText>
        <Form.Control
          name="verificationCode"
          accepter={MaskedInput}
          mask={masks.cardVerification}
          guide={false}
          autoComplete="cc-csc"
        />
      </Form.Group>
    </ValidatedForm>
  )
}

export {
  PaymentDetailsForm
}
