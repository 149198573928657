import React, { ReactElement, useEffect } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Panel, Loader } from 'rsuite'

import {
  usePageTitle, useFormState, useApi, useApiGroup
} from '../../app/hooks'
import { getOrganisation, createOrganisation, updateOrganisation } from '../../services/graphql/queries'
import { PanelHeader } from '../../components'
import { OrganisationForm } from '../../forms/OrganisationForm'
import { CreateOrganisationParams as OrganisationDetailsType } from '../../types/organisation'
import { NotFound } from '../error-pages/NotFound'

/**
 * Create/edit organisation component
 * @return {ReactElement}
 */
function OrganisationDetails (): ReactElement {
  const navigate = useNavigate()
  const { organisationId } = useParams()
  const api = useApiGroup({
    fetch: useApi(getOrganisation),
    create: useApi(createOrganisation),
    update: useApi(updateOrganisation),
  })

  const fetchResponse = api.requests.fetch.getResponse()
  const saveResponse = organisationId
    ? api.requests.update.getResponse()
    : api.requests.create.getResponse()

  const initialValues = organisationId ? fetchResponse.data : { name: '' }
  const [formData, setField] = useFormState(initialValues)

  useEffect(() => {
    if (organisationId) {
      api.requests.fetch.sendRequest({ organisationId })
    }
    return api.cleanup
  }, [])

  const pageTitle = organisationId ? 'Edit organisation' : 'New organisation'
  usePageTitle(pageTitle)

  if (fetchResponse.success && !fetchResponse.data) {
    return <NotFound />
  }

  if (saveResponse.success) {
    return <Navigate to="/admin/organisations" />
  }

  /**
   * Create or update a organisation using the API
   */
  const save = () => {
    const organisation = formData as OrganisationDetailsType
    if (organisationId) {
      api.requests.update.sendRequest({ organisationId, ...organisation })
    } else {
      api.requests.create.sendRequest(organisation)
    }
  }

  /**
   * Return to the organisations screen
   */
  const cancel = () => {
    navigate('/admin/organisations')
  }

  const formId = 'organisation-details-form'

  return (
    <Panel
      header={(
        <PanelHeader
          loading={saveResponse.loading}
          editing
          formId={formId}
          onCancel={cancel}
          header={pageTitle}
        />
      )}
    >
      {api.requests.fetch.getResponse().loading
        ? <Loader center content="Loading..." />
        : (
          <OrganisationForm
            formId={formId}
            formValue={formData}
            onChange={setField}
            onSubmit={save}
            error={saveResponse.error}
            mode={organisationId ? 'edit' : 'new'}
          />
        )}
    </Panel>
  )
}

export {
  OrganisationDetails
}
