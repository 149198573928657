import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Button, ButtonGroup, Panel, Table
} from 'rsuite'

import { usePageTitle, usePaginatedApi, useUser } from '../../app/hooks'
import { PaginationControls, TableData } from '../../components'
import { getUserVisits } from '../../services/graphql/queries'
import { getUserVisitsTableRow, visitDtoToVisit } from '../../types/visitHelpers'

import './CustomerVisitsList.css'

/**
 * Customer view of their visits in a list
 * @return {React.ReactElement}
 */
export function CustomerVisitsList () {
  const navigate = useNavigate()
  const { month } = useParams()

  let date = dayjs().startOf('month')

  const dateMatch = (useParams().month || '').match(/(?<year>\d{4})-(?<month>\d{2})/)
  if (dateMatch) {
    const yearPart = Number(dateMatch.groups?.year || null)
    const monthPart = Number(dateMatch.groups?.month || null)
    date = dayjs(new Date(yearPart, monthPart - 1, 1))
  }

  /**
   * Gets the date related parameters required for the query from a Dayjs object
   * @param {Dayjs} monthStart - The dayjs object for the start of the desired month
   */
  const getQueryDateParams = (monthStart: Dayjs): {toDate:string, fromDate:string} => ({
    toDate: monthStart.endOf('month').format(),
    fromDate: monthStart.format(),
  })

  const user = useUser()
  const visits = usePaginatedApi({
    query: getUserVisits,
    fetchParams: { userId: user.id, ...getQueryDateParams(date) },
  })

  useEffect(() => {
    visits.sendRequest({ userId: user.id, ...getQueryDateParams(date) })
  }, [month])

  const dateFormat = 'MMM YYYY'
  const monthString = date.format(dateFormat)

  const route = '/visits/'
  const routeDateFormat = 'YYYY-MM'

  /** Cb when user clicks the prev month btn */
  const prevMonth = () => {
    navigate(`${route}${date.subtract(1, 'month').format(routeDateFormat)}`)
  }

  /** Cb when user clicks the next month btn */
  const nextMonth = () => {
    navigate(`${route}${date.add(1, 'month').format(routeDateFormat)}`)
  }

  const tableData = useMemo(() => visits.currentPage?.map(visitDtoToVisit)
    .map(getUserVisitsTableRow) || [], [visits.currentPage])

  const isCurrentMonth = date.get('month') === dayjs().get('month')

  usePageTitle('Parking history')
  return (
    <div className="customer-visits-list">
      <Panel header={(
        <h2>
          Parking history
          {' - '}
          {monthString}
        </h2>
      )}
      >
        <div className="display-month">
          <ButtonGroup className="switch">
            <Button onClick={prevMonth}>Prev month</Button>
            <Button onClick={nextMonth} disabled={isCurrentMonth}>Next month</Button>
          </ButtonGroup>
        </div>
        <div className="table">
          <Table
            autoHeight
            wordWrap="break-word"
            data={tableData}
            loading={visits.loading}
          >
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <TableData dataKey="date" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Car park</Table.HeaderCell>
              <TableData dataKey="carparkName" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Vehicle</Table.HeaderCell>
              <TableData dataKey="vehicleRego" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Entry</Table.HeaderCell>
              <TableData dataKey="entryTime" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Exit</Table.HeaderCell>
              <TableData dataKey="exitTime" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Duration</Table.HeaderCell>
              <TableData dataKey="duration" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Access type</Table.HeaderCell>
              <TableData dataKey="accessType" format="tag" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Price</Table.HeaderCell>
              <TableData dataKey="fee" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Total price</Table.HeaderCell>
              <TableData dataKey="totalPrice" />
            </Table.Column>
          </Table>
          <div className="pagination">
            <PaginationControls
              nextPage={visits.next || undefined}
              prevPage={visits.prev || undefined}
            />
          </div>
        </div>

      </Panel>
    </div>
  )
}
