import React from 'react'
import { Field, useField } from 'formik'
import { Radio, Panel } from 'rsuite'
import { Input, SelectPicker, RadioGroup } from '@rsuite/formik'

import { useApi } from '../../app/hooks'
import { getOrganisations } from '../../services/graphql/queries'
import FieldWithFormik from '../formik-fields/FieldWithFormik'

export function TariffNamePage (
  { form }: { form: any }
) {
  const { setFieldValue, errors, touched } = form
  const [tariffAssignmentTypeField] = useField('tariffAssignmentType')
  const radioHandler = () => {
    setFieldValue('group', null)
    setFieldValue('organisation', null)
  }

  const api = useApi(getOrganisations)
  const organisationData = api.getResponse()
  const organisations = organisationData.success
    ? organisationData.data?.items.map((org) => ({ label: org.name, value: org.id })) ?? [] : []

  React.useEffect(() => {
    api.sendRequest()
    return api.cleanup
  }, [])
  const { value } = tariffAssignmentTypeField
  let picker = null
  if (value === 'group') {
    picker = (
      <FieldWithFormik
        errors={errors}
        touched={touched}
        name="group"
        label="Select the group to assign the tariff to:"
        data={[
          { label: 'Hospital Staff', value: 'group_1_id' },
          { label: 'Loyalty Program', value: 'group_2_id' },
        ]}
        component={SelectPicker}
        required
      />
    )
  }
  if (value === 'organisation') {
    picker = (
      <FieldWithFormik
        errors={errors}
        touched={touched}
        name="organisation"
        label="Select the organisation to assign the tariff to:"
        data={organisations}
        component={SelectPicker}
        required
      />
    )
  }
  return (
    <>
      <Panel header="Tariff details">
        <FieldWithFormik
          errors={errors}
          touched={touched}
          required
          name="name"
          label="Enter a unique name for the tariff:"
          component={Input}
        />

        <FieldWithFormik
          errors={errors}
          touched={touched}
          name="cameraGroup"
          label="Assign to a camera group:"
          data={[{ label: 'Tidal', value: 'tidal_id' }, { label: 'Nest', value: 'nest_id' }]}
          component={SelectPicker}
        />
      </Panel>
      <Panel header="Assign tariff to users or groups">
        <p>
          The tariff will apply to:
        </p>
        <Field name="tariffAssignmentType" component={RadioGroup} onChange={radioHandler}>
          <Radio value="all">All casual users</Radio>
          <Radio value="group">Specific group</Radio>
          <Radio value="organisation">Specific organisation</Radio>
        </Field>
        {picker}
      </Panel>
    </>
  )
}
