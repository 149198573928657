import React, { ReactElement } from 'react'
import { Panel, Table } from 'rsuite'

import { TableData } from '../../components'
import { Plan, PlanWithDefaultIndicator, Pricing } from '../../types/pricing'
import { RowData } from '../../types/table'
import { pricingIsPlan } from '../../types/pricingHelpers'

type Props = {
  pricings: Pricing[]
}

/**
 * Table.Cell child for rendering a plan's name
 * @param {PlanWithDefaultIndicator} plan
 * @return {ReactElement}
 */
const renderName = (plan: RowData): ReactElement => (
  <div>
    {plan.name}
    {plan.isDefault ? ' (Default Plan)' : ''}
  </div>
)

/**
 * Table.Cell child for rendering a plan's price
 * @param {Pricing} plan
 * @return {ReactElement}
 */
const renderPricing = (plan: RowData): ReactElement => (
  <div>
    $
    {plan.price}
  </div>
)

/**
 * Component which details the pricing plans by name and price
 * @param {Props} props
 * @param {Pricing[]} props.pricings - pricing items to be displayed
 * @return {ReactElement}
 *
 */
function LocationPricing (props: Props): ReactElement {
  const { pricings } = props

  const plans: Plan[] = []

  pricings.forEach((price) => {
    if (pricingIsPlan(price)) {
      plans.push(price)
    }
    // else its the wrong type
  })

  const tableData: PlanWithDefaultIndicator[] = plans.map((e, i) => ({
    ...e,
    isDefault: i === 0,
  }))

  return (
    <Panel header={<h2>Pricing plans</h2>}>
      <Table
        data={tableData}
        wordWrap="break-word"
        autoHeight
      >
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <TableData dataKey="name" content={renderName} />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Price</Table.HeaderCell>
          <TableData dataKey="price" content={renderPricing} />
        </Table.Column>
      </Table>
    </Panel>
  )
}

export { LocationPricing }
