import { createQuery } from '../helpers'
import type {
  CreateTariffParams,
  TariffConfig,
  RemoveTariffParams,
  UpdateTariffParams,
  GetLocationTariffParams,
  Tariff
} from '../../../types/tariff'

import type { PaginatedResponse, Query } from '../../../types/api'

const returnProps = `
  id
  name
  group
  organisation
  location
  cameraGroup
  days
  date
  hours
  entryHours
  exitHours
  fixedFees
  fixedGrace
  tariffAssignmentType
  tariffRateType
  tariffDateType
  rates {
    step
    period
    unit
    rate
  }
`

const getLocationTariffs: Query<PaginatedResponse<TariffConfig>, GetLocationTariffParams > = createQuery(`
  query getLocationTariffs($locationId: ID!) {
    getLocationTariffs (location: $locationId) { 
      items {
        ${returnProps} 
      }
      next
      limit
    }
  }
`)

const getTariffConfig: Query<Tariff, { tariffId: string }> = createQuery(`
  query getTariffConfig($tariffId: ID!) {
    getTariffConfig (id: $tariffId) { ${returnProps} }
  }
`)

const createTariff: Query<TariffConfig, CreateTariffParams> = createQuery(`
  mutation createTariff(
    $location: ID!,
    $name: String!,
    $group: String,
    $organisation: String,
    $cameraGroup: String,
    $days: [String],
    $date: String,
    $hours: [String],
    $entryHours: [String],
    $exitHours: [String],
    $fixedFees: String,
    $fixedGrace: String,
    $tariffAssignmentType: String,
    $tariffRateType: String,
    $tariffDateType: String
  ) {
    createTariff(
      location: $location,
      name: $name,
      group: $group,
      organisation: $organisation,
      cameraGroup: $cameraGroup,
      days: $days,
      date: $date,
      hours: $hours,
      entryHours: $entryHours,
      exitHours: $exitHours,
      fixedFees: $fixedFees,
      fixedGrace: $fixedGrace,
      tariffAssignmentType: $tariffAssignmentType,
      tariffRateType: $tariffRateType,
      tariffDateType: $tariffDateType
    ) { ${returnProps} }
  }
`)

const updateTariff: Query<Tariff, UpdateTariffParams> = createQuery(`
  mutation editTariff(
    $tariffId: ID!,
    $location: ID!,
    $name: String!,
    $group: String,
    $organisation: String,
    $cameraGroup: String,
    $days: [String],
    $date: String,
    $hours: [String],
    $entryHours: [String],
    $exitHours: [String],
    $fixedFees: String,
    $fixedGrace: String,
    $tariffAssignmentType: String,
    $tariffRateType: String,
    $tariffDateType: String
  ) {
    editTariff(
      id: $tariffId,
      location: $location,
      name: $name,
      group: $group,
      organisation: $organisation,
      cameraGroup: $cameraGroup,
      days: $days,
      date: $date,
      hours: $hours,
      entryHours: $entryHours,
      exitHours: $exitHours,
      fixedFees: $fixedFees,
      fixedGrace: $fixedGrace,
      tariffAssignmentType: $tariffAssignmentType,
      tariffRateType: $tariffRateType,
      tariffDateType: $tariffDateType
    ) { ${returnProps} }
  }
`)

const removeTariff: Query<void, RemoveTariffParams> = createQuery(`
  mutation removeTariff($tariffId: ID!) {
    removeTariff(id: $tariffId)
  }
`)

export {
  getLocationTariffs,
  createTariff,
  updateTariff,
  getTariffConfig,
  removeTariff
}
