import { createQuery } from '../helpers'
import type { Payment } from '../../../types/payment'
import type { Query } from '../../../types/api'

/**
 * @var string
 * The properties to get back in any payment response
 */
const returnProps = `
  id
  type
  amount
  outstanding
  surcharge
  status
  created
  processed
  product {
    ...on Subscription {
      plan {
        name
      }
    }
  }
  location {
    name
  }
`

const getOutstandingPayments: Query<Payment[], { userId: string }> = createQuery(`
  query getOutstandingPayments($userId: ID!) {
    getOutstandingPayments(user: $userId) { ${returnProps} }
  }
`)

const retryOutstandingPayments: Query<Payment[], { userId: string }> = createQuery(`
  mutation retryOutstandingPayments($userId: ID!) {
    retryOutstandingPayments(user: $userId) { ${returnProps} }
  }
`)

export {
  getOutstandingPayments,
  retryOutstandingPayments
}
