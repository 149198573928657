import React, { useEffect } from 'react'
import { Loader } from 'rsuite'
import { useNavigate } from 'react-router-dom'

import { LocationWithDefaultPrice } from '../../types/location'

import './CustomerConfirmSubscription.css'
import { ConfirmSubscriptionForm } from '../../forms/ConfirmSubscriptionForm'
import { useCustomerSubscription, useFormState } from '../../app/hooks'
import { DefinitionList } from '../../components'
import { pricingIsPlan } from '../../types/pricingHelpers'

type Props = {
  loading?:boolean,
  location: LocationWithDefaultPrice
}

/**
 * Feature component for customers to confirm their subscription.
 * @param {Props} props
 * @param {Boolean} props.loading                     - Whether to display this component's loading state
 * @param {LocationWithDefaultPrice} props.location   - The intended location along with its pricing for
 *                                                      the subscription
 * @return {React.ReactElement}
 */
function CustomerConfirmSubscription (props:Props): React.ReactElement {
  const navigate = useNavigate()

  const { location: currentLocation, loading } = props
  let price = '0'
  if (pricingIsPlan(currentLocation?.pricing)) {
    price = currentLocation?.pricing.price
  }

  const [formData, setField] = useFormState({
    numVehicles: 1,
    agreementCheckbox: 0,
  })

  // We need to display the total amount the user will
  // be paying for this subscription based on the number vehicles requested
  const priceNumber = Number(price)
  let total = 0
  if (!Number.isNaN(priceNumber)) {
    total = priceNumber * formData.numVehicles
  }

  const {
    addSubscription,
    addSubscriptionLoading,
    addSubscriptionError,
    addSubscriptionResponse,
  } = useCustomerSubscription()

  const handleAddSubscription = () => {
    if (currentLocation !== null) {
      addSubscription(currentLocation.id, formData.numVehicles)
    }
  }

  useEffect(() => {
    if (addSubscriptionResponse !== null) {
      navigate('/subscriptions')
    }
  }, [addSubscriptionResponse])

  const locationData = {
    'Carpark name': currentLocation?.name,
    Address: currentLocation?.address,
  }

  const pricingData = {
    'Monthly price per parking bay': `$${price}`,
  }

  return (
    <div className="confirm-subscription">
      {
        loading
          ? (
            <div className="loader">
              <Loader
                size="md"
                center
                content="Loading location..."
              />
            </div>
          )
          : (
            <div>
              <div className="section">
                <div className="sectiontitle">Location</div>
                <DefinitionList data={locationData} />
              </div>
              <div className="section">
                <div className="sectiontitle">About Subscriptions</div>
                <p>
                  Subscriptions are paid for on a monthly basis. They are priced per number of vehicle bays
                  you request. Upon creating your subscription, your subscription
                  will have a status of PENDING. Your subscription will remain PENDING
                  until an adminstrator approves your request, your subscription then will be ACTIVE.
                </p>
              </div>
              <div className="section">
                <div className="sectiontitle">Pricing</div>
                <div className="totaldisplay">
                  <div className="totallabel">Total:</div>
                  $
                  {total.toFixed(2)}
                  <div className="fee">
                    Excludes 2.5% transaction fee
                  </div>
                </div>
                <div className="price">
                  <DefinitionList data={pricingData} />
                </div>
              </div>
              <div className="section">
                <div className="sectiontitle">Parking bay allocation</div>
                <div className="form">
                  <ConfirmSubscriptionForm
                    formValue={formData}
                    onChange={setField}
                    onSubmit={handleAddSubscription}
                    loading={addSubscriptionLoading}
                    error={addSubscriptionError}
                  />
                </div>
              </div>
            </div>
          )
      }
    </div>
  )
}

export {
  CustomerConfirmSubscription
}
