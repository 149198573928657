import { createQuery } from '../helpers'
import type {
  Organisation, CreateOrganisationParams,
  UpdateOrganisationParams
} from '../../../types/organisation'
import type { PaginatedResponse, Query } from '../../../types/api'

/**
 * @var string
 * The properties to get back in any organisation response
 */
const returnProps = `
  id
  name
`

const getOrganisation: Query<Organisation, { organisationId: string }> = createQuery(`
  query getOrganisation($organisationId: ID!) {
    getOrganisation(id: $organisationId) { ${returnProps} }
  }
`)

const getOrganisations: Query<PaginatedResponse<Organisation>> = createQuery(`
  query getOrganisations {
    getOrganisations { 
      items {
        ${returnProps} 
      }
      next
      limit
    }
  }
`)

const createOrganisation: Query<Organisation, CreateOrganisationParams> = createQuery(`
  mutation createOrganisation(
    $name: String!,
  ) {
    createOrganisation(
      name: $name,
    ) { ${returnProps} }
  }
`)

const updateOrganisation: Query<Organisation, UpdateOrganisationParams> = createQuery(`
  mutation editOrganisation(
    $organisationId: ID!,
    $name: String,
  ) {
    editOrganisation(
      id: $organisationId,
      name: $name,
    ) { ${returnProps} }
  }
`)

export {
  getOrganisation,
  getOrganisations,
  createOrganisation,
  updateOrganisation
}
