import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Panel, SelectPicker } from 'rsuite'

import { useApi, usePageTitle } from '../../app/hooks'
import { CenteredLoader } from '../../components'
import { getSubscriptionDatesBilled } from '../../services/graphql/queries'
import './SubscriptionDatesBilled.css'

/**
 * Component which displays the billed dates for a subscription
 * @return {ReactElement}
 */
export function SubscriptionDatesBilled ():React.ReactElement {
  const { id } = useParams()

  usePageTitle('Subscription invoice')

  const [pickedDate, setPickedDate] = useState<string|null>(null)

  const query = useApi(getSubscriptionDatesBilled, {
    initialFetchParams: {
      subscriptionId: id ?? '',
    },
    initialFetch: true,
    displayErrorAlerts: true,
  })
  const response = query.getResponse()
  const navigate = useNavigate()

  if (response.loading || !response.data) {
    return <CenteredLoader />
  }

  const reformatDate = (date:string) => {
    const element = date.split('-')
    const elementsReversed = element.reverse()
    return elementsReversed.join('/')
  }

  const getInvoice = () => {
    navigate(`/subscriptions/invoice/${id}/${pickedDate ?? ''}`)
  }

  const items = response.data.map((e) => ({
    label: reformatDate(e),
    value: e,
  }))

  return (
    <div className="subscription-dates-billed">
      <Panel header={<h2>Select the billing date of the invoice</h2>}>
        <div className="picker">
          <SelectPicker data={items} value={pickedDate} onChange={(str) => setPickedDate(str)} />
        </div>
        <Button onClick={getInvoice}>Get invoice</Button>
      </Panel>
    </div>
  )
}
