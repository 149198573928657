import { TypeAttributes } from 'rsuite/esm/@types/common'

import { SubscriptionStatus } from '../../types/enums'

/**
 *  Returns a tag color which matches a subscription status.
 *  For some reason, for a RSuite tag to display its default colour, I have to return `undefined`
 *  This makes me annoyed.
 *  @param {SubscriptionStatus} status
 *  @return {TypeAttributes.Color|undefined}
 */
function getSubscriptionStatusTagColor (status:SubscriptionStatus): TypeAttributes.Color | undefined {
  switch (status) {
    case SubscriptionStatus.PENDING:
      return 'yellow'
    case SubscriptionStatus.ACTIVE:
      return 'green'
    case SubscriptionStatus.UNPAID:
      return 'red'
    case SubscriptionStatus.CANCELLED:
      return undefined
    default:
      return undefined
  }
}

const isSubscriptionActive = (status: SubscriptionStatus): boolean => (
  [SubscriptionStatus.ACTIVE, SubscriptionStatus.UNPAID].includes(status)
)

export {
  getSubscriptionStatusTagColor,
  isSubscriptionActive
}
