import React from 'react'

import { Link } from 'react-router-dom'

import {
  DashboardNewItem, DashboardProfileCard, DashboardVehicleItem
} from '../../components'
import { CustomerDashboardInfo } from '../../types/dashboard'
import { CustomerDashboardBanner } from './CustomerMemberDashboardBanner'
import { CustomerDashboardSection } from './CustomerDashboardSection'

import './CustomerDashboard.css'

/** Prop type for {@link CustomerDashboard} */
type CustomerDashboardProps = CustomerDashboardInfo

/**
 * Customer dashboard which displays the profile status of the customer
 * and the customer's subscriptions & vehicles
 */
function CustomerDashboard (props: CustomerDashboardProps):React.ReactElement {
  const {
    vehicles,
    vehiclesActive,
    subscriptionsActive,
    paymentMethodExists,
    addPaymentMethodCb,
    addSubscriptionCb,
    addVehicleCb,
    vehicleIcon,
    subscriptionIcon,
    paymentIcon,
  } = props

  return (
    <div className="customer-dashboard">
      <div className="casualbanner">
        <div className="casual-parking-banner">
          <div className="post">
            <img alt="parking-sign" src="/images/parking-sign.svg" />
          </div>
          <span className="title">
            Permanent Parking subscriptions available!
          </span>
          <span className="alternative">
            Subscribe
            {' '}
            <Link to="/subscriptions"> here </Link>
          </span>
        </div>
      </div>
      <CustomerDashboardBanner
        vehiclesActive={vehiclesActive}
        subscriptionsActive={subscriptionsActive}
        paymentMethodExists={paymentMethodExists}
        addPaymentMethodCb={addPaymentMethodCb}
        addSubscriptionCb={addSubscriptionCb}
        addVehicleCb={addVehicleCb}
        vehicleIcon={vehicleIcon}
        subscriptionIcon={subscriptionIcon}
        paymentIcon={paymentIcon}
      />
      <CustomerDashboardSection
        title="Your profile"
        description={
          'Add or update vehicle details, change payment details and'
        + ' manage subscriptions for a better parking experience'
        }
        sectionType="profile"
        manageRoute=""
      >
        <DashboardProfileCard
          title="1. Payment method"
          ctaLabel="Add payment method"
          ctaIcon={paymentIcon}
          description="Add a payment method for your account"
          ctaAction={addPaymentMethodCb}
          complete={paymentMethodExists}
        />
        <DashboardProfileCard
          title="2. Vehicles"
          ctaLabel="Add a vehicle"
          ctaIcon={vehicleIcon}
          description="Add any number of vehicles to use with your account"
          ctaAction={addVehicleCb}
          complete={vehiclesActive}
        />
      </CustomerDashboardSection>
      <CustomerDashboardSection
        title={vehicles.title}
        description={vehicles.description}
        sectionType="vehicles"
        manageRoute={vehicles.manageRoute}
      >
        {
          vehicles.data.map((e) => (
            <DashboardVehicleItem
              key={e.id}
              vehicleMake={e.make}
              vehicleModel={e.model}
              vehicleRego={e.rego}
            />
          ))
        }
        <DashboardNewItem
          description={vehicles.addItemDescription}
          ctaIcon={vehicles.itemIcon}
          addItemCb={vehicles.addItemCb}
          ctaLabel={vehicles.addItemLabel}
        />
      </CustomerDashboardSection>
    </div>
  )
}

export {
  CustomerDashboard
}
