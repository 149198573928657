import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'

import { OrganisationsPage } from './admin/organisations'
import { LocationsPage } from './admin/locations'
import { RequireLogin } from '../features/access/RequireLogin'
import { RequireRole } from '../features/access/RequireRole'
import { SidebarLayout } from '../layouts/SidebarLayout'
import { AdminMenu } from '../features/navigation/AdminMenu'
import { NotFound } from '../features/error-pages/NotFound'
import { UserRole } from '../types/enums'
import { SubscriptionsPage } from './admin/subscriptions'
import { UsersPage } from './admin/users'
import { ReportingPage } from './admin/reporting'

function AdminRouter (): ReactElement {
  return (
    <RequireLogin>
      <RequireRole roles={[UserRole.ADMIN, UserRole.ORGANISATION]}>
        <SidebarLayout menu={<AdminMenu />}>
          <Routes>
            <Route path="/organisations/*" element={<OrganisationsPage />} />
            <Route path="/users/*" element={<UsersPage />} />
            <Route path="/subscriptions/*" element={<SubscriptionsPage />} />
            <Route path="/locations/*" element={<LocationsPage />} />
            <Route path="/reporting" element={<ReportingPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </SidebarLayout>
      </RequireRole>
    </RequireLogin>
  )
}

export {
  AdminRouter
}
