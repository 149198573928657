import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'

import { Dashboard } from './customer/dashboard'
import { AccountPage } from './customer/account'
import { VehiclesPage } from './customer/vehicles'
import { RequireLogin } from '../features/access/RequireLogin'
import { SidebarLayout } from '../layouts/SidebarLayout'
import { CustomerMenu } from '../features/navigation/CustomerMenu'
import { PaymentReminder } from '../features/payments/PaymentReminder'
import { NotFound } from '../features/error-pages/NotFound'
import { SubscriptionsPage } from './customer/subscription'
import { PaymentsPage } from './customer/payments'
import { CustomerVisitsPage } from './customer/visits'
import { EmailVerficationReminder } from '../features/account/EmailReminder'

function CustomerRouter (): ReactElement {
  return (
    <RequireLogin>
      <SidebarLayout menu={<CustomerMenu />}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/account/*" element={<AccountPage />} />
          <Route path="/vehicles/*" element={<VehiclesPage />} />
          <Route path="/subscriptions/*" element={<SubscriptionsPage />} />
          <Route path="/payments/*" element={<PaymentsPage />} />
          <Route path="/visits/*" element={<CustomerVisitsPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </SidebarLayout>
      <PaymentReminder />
      <EmailVerficationReminder />
    </RequireLogin>
  )
}

export {
  CustomerRouter
}
