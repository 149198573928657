import React, { ReactElement, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import { Panel, Table } from 'rsuite'
import { Icon } from '@rsuite/icons'

import {
  ActionMenu, PanelHeader, TableData, Modal, ConfirmModal
} from '../../components'
import { getLocationAccessLists, removeAccessList } from '../../services/graphql/queries/access-lists'
import { useApi, usePaginatedApi, useModal } from '../../app/hooks'
import { RowData } from '../../types/table'
import type { Location } from '../../types/location'
import type { AccessList } from '../../types/access-list'
import { deleteFromPaginatedWithId } from '../../helpers'
import { AccessListType } from '../../types/enums'

/**
 * Get a message detailing the effect of returning a type of access list
 * @param {AccessListType} type
 * @return {string}
 */
const getRemovalMessage = (type: AccessListType|undefined): string => {
  if (!type) {
    return ''
  }
  const messages = {
    [AccessListType.ALLOW]: 'Vehicles on this list will no longer be automatically admitted to the car park.',
    [AccessListType.DENY]: 'Vehicles on this list will no longer be blocked from entering the car park.',
  }
  return messages[type] || ''
}

type Props = {
  location: Location,
}

/**
 * A component to display a list of access lists
 * @param {Props} props
 * @return {ReactElement}
 */
function AccessListList (props: Props):ReactElement {
  const { location } = props
  const navigate = useNavigate()

  const [listForRemoval, setListForRemoval] = useState<AccessList>()
  const modal = useModal()

  const fetchQuery = usePaginatedApi({
    query: getLocationAccessLists,
    fetchParams: {
      location: location.id,
    },
    queryOptions: {
      cleanUpOnDismount: true,
      displayErrorAlerts: true,
    },
  })

  const deleteQuery = useApi(removeAccessList)

  /**
   * Show the access list removal confirmation modal
   * @param {RowData} accessList - The access list to remove
   */
  const confirmRemoval = (accessList: RowData): void => {
    setListForRemoval(accessList as AccessList)
    modal.show()
  }

  /**
   * Remove the access list from the location
   */
  const remove = () => {
    if (listForRemoval) {
      deleteQuery.sendRequest({
        accessListId: listForRemoval.id,
      }, () => {
        fetchQuery.apiHook.setData((oldData) => deleteFromPaginatedWithId(oldData, listForRemoval.id))
      })
    }
  }

  /**
   * Render the name of an access as a hyperlink
   * @param {RowData} accessList
   * @return {ReactElement}
   */
  const renderNameLink = ({ id, name }: RowData): ReactElement => (
    <Link to={`/admin/locations/${location.id}/access-lists/${id}`}>
      {name}
    </Link>
  )

  /**
   * Render the action menu for an access list
   * @param {RowData} accessList
   * @return {ReactElement}
   */
  const renderActions = (accessList: RowData): ReactElement => (
    <ActionMenu actions={[{
      label: 'Delete',
      action: () => confirmRemoval(accessList),
    }]}
    />
  )

  return (
    <>
      <Panel
        header={(
          <PanelHeader
            header="Access lists"
            onEdit={() => navigate('access-lists/new')}
            icon={<Icon as={FaPlus} />}
          />
        )}
      >
        <Table
          data={fetchQuery.currentPage || []}
          autoHeight
        >
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <TableData
              dataKey="name"
              content={
                renderNameLink
              }
            />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <TableData dataKey="type" format="tag" />
          </Table.Column>
          <Table.Column flexGrow={0.6}>
            <Table.HeaderCell>Vehicles</Table.HeaderCell>
            <TableData dataKey="items" content={({ items }) => items.length} />
          </Table.Column>
          <Table.Column flexGrow={0.6}>
            <Table.HeaderCell>Actions</Table.HeaderCell>
            <TableData dataKey="Actions" content={renderActions} />
          </Table.Column>
        </Table>
      </Panel>
      <Modal hook={modal}>
        <ConfirmModal
          message={`The ${listForRemoval?.name} access list will be removed from this location.
            ${getRemovalMessage(listForRemoval?.type)}`}
          onConfirm={remove}
          onClose={modal.hide}
        />
      </Modal>
    </>
  )
}

export {
  AccessListList
}
