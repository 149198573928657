import React, { ReactElement } from 'react'
import { Form } from 'rsuite'

import { ValidatedForm } from '../components'
import * as schemas from './validation/schemas'
import { Validator } from './validation/validator'
import type { FormProps } from '../types/form'

/**
 * Organisation form component
 * @param {Props} props
 * @return {ReactElement}
 */
function OrganisationForm (props: FormProps): ReactElement {
  const {
    formValue, onChange, onSubmit, error, formId,
  } = props

  const validator = new Validator({
    name: {
      model: schemas.required('Please enter a name for the organisation'),
    },
  })

  return (
    <ValidatedForm
      fluid
      id={formId}
      validator={validator}
      formValue={formValue}
      onChange={onChange}
      onSubmit={onSubmit}
      error={error}
    >
      <Form.Group controlId="name">
        <Form.ControlLabel>Name</Form.ControlLabel>
        <Form.Control name="name" type="text" />
      </Form.Group>
    </ValidatedForm>
  )
}

export {
  OrganisationForm
}
