import React, { ReactElement } from 'react'
import {
  Popover, Dropdown, Button, Whisper
} from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FaEllipsisH } from 'react-icons/fa'

import './ActionMenu.css'

type Props = {
  actions: {
    action: () => void,
    label: string,
  }[],
}

/**
 * A dropdown-style menu for use in tables
 * This is required as the Rsuite dropdown menu cannot be used in a table cell
 * due to the way Rsuite renders the cells with fixed height and overflow hidden.
 * @param {Props} props
 * @return {ReactElement}
 */
function ActionMenu (props: Props): ReactElement {
  const { actions } = props
  const speaker = (
    <Popover className="action-menu">
      <Dropdown.Menu>
        {actions.map(({ action, label }) => (
          <Dropdown.Item onClick={action} key={label}>{label}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Popover>
  )

  return (
    <Whisper trigger="click" placement="bottom" speaker={speaker}>
      <Button className="action-menu-button" appearance="subtle" disabled={actions.length === 0}>
        <Icon as={FaEllipsisH} />
      </Button>
    </Whisper>
  )
}

export {
  ActionMenu
}
