import React, { ReactElement } from 'react'
import { Navigate, Link } from 'react-router-dom'
import { Panel, Button } from 'rsuite'

import {
  useSelector, useFormState, useDispatch, usePageTitle
} from '../../app/hooks'
import { userSelectors, userActions } from '../../store/user/slice'
import { SignupForm } from '../../forms/SignupForm'
import { normalisePhoneNumber, normaliseEmailAddress } from './helpers'
import { NooverLogo } from '../../components'

/**
 * Signup panel component
 * @return {ReactElement}
 */
function Signup (): ReactElement {
  usePageTitle('Sign up')
  const dispatch = useDispatch()
  const [formData, setField] = useFormState({
    email: '',
    password: '',
    givenName: '',
    familyName: '',
    phone: '',
    agreementCheckbox: 0,
  })
  const status = useSelector(userSelectors.getStatus)
  const user = useSelector(userSelectors.getUser)

  if (user && user.unconfirmed) return <Navigate to="/login/confirm" />

  /**
   * Submit the signup form after validation is successful
   * @param {boolean} valid
   */
  const signup = (): void => {
    dispatch(userActions.signup({
      credentials: {
        username: normaliseEmailAddress(formData.email),
        password: formData.password,
      },
      details: {
        givenName: formData.givenName,
        familyName: formData.familyName,
        email: normaliseEmailAddress(formData.email),
        phone: normalisePhoneNumber(formData.phone),
      },
    }))
  }

  return (
    <div data-testid="signup-page" className="login-page">
      <div className="welcome">
        <img src="/images/boom-gate-closed.svg" alt="" />
        <NooverLogo aria-label="Noover" inverted />
        <ul>
          <li>
            <h2>Choose a car park that suits you</h2>
            <p>Your account can be used with multiple car park locations.</p>
          </li>
          <li>
            <h2>Park without the need for a ticket</h2>
            <p>Swipe cards and tickets become a thing of the past.</p>
          </li>
          <li>
            <h2>Unlimited vehicles</h2>
            <p>Add as many vehicles as you wish.</p>
          </li>
        </ul>
      </div>
      <div className="form -signup">
        <Panel header="Create your account">
          <p>Let&apos;s get started with the basics. We&apos;ll have you parking in no time.</p>
          <SignupForm
            formValue={formData}
            onChange={setField}
            onSubmit={signup}
            loading={status.loading}
            error={status.error}
          />
          <p>
            Have an account?
            <Button appearance="link" as={Link} to="/login">Sign in</Button>
          </p>
        </Panel>
      </div>
      <div className="mobilewelcomelist">
        <ul>
          <li>
            <h2>Choose a car park that suits you</h2>
            <p>Your account can be used with multiple car park locations.</p>
          </li>
          <li>
            <h2>Park without the need for a ticket</h2>
            <p>Swipe cards and tickets become a thing of the past.</p>
          </li>
          <li>
            <h2>Unlimited vehicles per subscription</h2>
            <p>Add as many vehicles as you wish.</p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export {
  Signup
}
