import { useSelector } from '.'
import { userSelectors } from '../../store/user/slice'
import type { UserRole } from '../../types/enums'

/**
 * A hook for getting read-only details of the current user. For full
 * control over the user data, use the user store directly
 */
const useUser = () => {
  const user = useSelector(userSelectors.getAuthUser)

  /**
   * Check if the current user has a role
   * @param {UserRole[]} roles - One or more roles to check
   * @return {boolean}
   */
  const hasRole = (...roles: Array<UserRole>): boolean => (
    !!user && roles.some((r) => user.roles.includes(r))
  )

  return {
    ...user,
    hasRole,
  }
}

export {
  useUser
}
