import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'

import { RequireRole } from '../../features/access/RequireRole'
import { OrganisationList } from '../../features/organisations/OrganisationList'
import { OrganisationDetails } from '../../features/organisations/OrganisationDetails'
import { UserRole } from '../../types/enums'

/**
 * OrganisationsPage route
 * @return {ReactElement}
 */
function OrganisationsPage (): ReactElement {
  return (
    <RequireRole role={UserRole.ADMIN}>
      <Routes>
        <Route path="/" element={<OrganisationList />} />
        <Route path="/new" element={<OrganisationDetails />} />
        <Route path="/:organisationId" element={<OrganisationDetails />} />
      </Routes>
    </RequireRole>
  )
}

export {
  OrganisationsPage
}
