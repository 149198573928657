import React, {
  ReactElement, useState
} from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Input,
  Panel, Table, TagPicker
} from 'rsuite'

import { usePageTitle } from '../../app/hooks'
import { getUsers } from '../../services/graphql/queries'
import { PaginationControls, TableData } from '../../components'
import type { RowData } from '../../types/table'
import { usePaginatedApi } from '../../app/hooks/paginatedApi'
import './UserList.css'
import { formatUserDisplayName, formatUserRoles } from './userHelpers'
import { Flag } from '../../types/enums'

/**
 * User list component
 * @return {ReactElement}
 */
export function UserList (): ReactElement {
  usePageTitle('Users')

  const usersQuery = usePaginatedApi({
    query: getUsers,
    fetchParams: {},
  })

  /**
   * Render a name cell for a single user
   * @param {User} user
   * @return {ReactElement}
   */
  const renderName = (user: RowData): ReactElement => (
    <Link to={user.id}>{formatUserDisplayName(user as any)}</Link>
  )

  /**
   * Render a email cell for a single user
   * @param {User} user
   * @return {ReactElement}
   */
  const renderEmail = (user: RowData): ReactElement => (
    <div>{user.email}</div>
  )

  /**
   * Render a roles cell for a single user
   * @param {User} user
   * @return {ReactElement}
   */
  const renderRoles = (user: RowData): ReactElement => <div>{formatUserRoles(user as any)}</div>

  const users = usersQuery.currentPage || []

  const [searchString, setSearchString] = useState('')
  const [flags, setFlags] = useState<Flag[]>([])

  const tagPickerOptions = Object.values(Flag).map((e) => ({
    label: e,
    value: e,
  }))

  /**
   *  Resets the query data and makes a new request with
   *  the search string in the search bar
   *  @param {String?} query          - The search string
   *  @param {String[]?} filterFlags  - The flags to filter users by, leave null if all flags are wanted
   */
  const performSearch = (query: string | null, filterFlags: string[] | null) => {
    usersQuery.reset()
    const hook = usersQuery.apiHook
    hook.sendRequest({
      query: query ?? undefined,
      flags: filterFlags as any ?? undefined,
    })
  }

  /**
   *  Cb when the search button is clicked
   */
  const searchUsers = () => {
    performSearch(searchString, flags)
  }

  /**
   *  Cb when the tag picker value changes
   *  @param {String[]} value - The values chosen in the TagPicker
   */
  const onTagPickerChange = (value:string[]) => {
    // TODO: type this properly
    setFlags(value as any)
    performSearch(searchString, value)
  }

  return (
    <Panel
      header={(
        <h2>Users</h2>
      )}
      className="user-list"
    >
      <div className="search">
        <div className="flag-filter">
          {/* eslint-disable-next-line  jsx-a11y/label-has-associated-control */}
          <label htmlFor="flags">Flag filter: </label>
          <TagPicker
            id="flags"
            name="flags"
            preventOverflow
            value={flags}
            data={tagPickerOptions}
            onChange={onTagPickerChange}
          />
        </div>
        <div className="search-bar">
          <div className="input">
            {/* eslint-disable-next-line  jsx-a11y/label-has-associated-control */}
            <label htmlFor="search">Search users:</label>
            <Input
              onKeyDown={(e) => {
                if (e.code === 'Enter') searchUsers()
              }}
              value={searchString}
              onChange={(s) => setSearchString(s)}
              name="search"
              id="search"
              type="text"
            />
          </div>
          <div className="button">
            <Button onClick={searchUsers}>Search</Button>
          </div>
        </div>
      </div>
      <Table
        autoHeight
        wordWrap="break-word"
        data={users}
        loading={usersQuery.loading}
      >
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <TableData dataKey="name" content={renderName} />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <TableData dataKey="name" content={renderEmail} />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Role</Table.HeaderCell>
          <TableData dataKey="name" content={renderRoles} />
        </Table.Column>
      </Table>
      <div className="pagination">
        <PaginationControls
          nextPage={usersQuery.next || undefined}
          prevPage={usersQuery.prev || undefined}
        />
      </div>
    </Panel>
  )
}
