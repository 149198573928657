import React, { ReactElement } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'rsuite'

type Props = {
  children: React.ReactNode,
  hook: {
    open: boolean,
    show: () => void,
    hide: () => void,
  },
}

/**
 * A modal component
 * @param {Props} props
 * @return {ReactElement}
 */
function ModalPortal (props: Props): ReactElement {
  const { hook, children } = props

  const wrapper = document.getElementById('root')
  if (!wrapper) {
    throw new Error('No wrapper found for modal')
  }

  return createPortal(
    <Modal
      backdrop="static"
      role="alertdialog"
      open={hook.open}
      onClose={hook.hide}
    >
      {children}
    </Modal>,
    wrapper
  )
}

export {
  ModalPortal as Modal
}
