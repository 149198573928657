import React, { ReactElement } from 'react'
import { Form } from 'rsuite'

import { ValidatedForm } from '../components'
import * as schemas from './validation/schemas'
import { Validator } from './validation/validator'
import { UserErrorCode as Errors } from '../types/enums'
import type { FormProps } from '../types/form'

/**
 * User details form component
 * @return {ReactElement}
 */
function UserDetailsForm (props: FormProps): ReactElement {
  const {
    formValue, onChange, onSubmit, error, formId,
  } = props

  const validator = new Validator({
    givenName: {
      model: schemas.required('Please provide your given name'),
    },
    familyName: {
      model: schemas.required('Please provide your family name'),
    },
    email: {
      model: schemas.email('Please provide your email address'),
      errors: [Errors.USERNAME_EXISTS],
    },
    phone: {
      model: schemas.phoneNumber('Please provide your phone number'),
    },
  })

  return (
    <ValidatedForm
      fluid
      id={formId}
      validator={validator}
      formValue={formValue}
      onChange={onChange}
      onSubmit={onSubmit}
      error={error}
    >
      <Form.Group controlId="givenName">
        <Form.ControlLabel>First name</Form.ControlLabel>
        <Form.Control name="givenName" type="text" />
      </Form.Group>
      <Form.Group controlId="familyName">
        <Form.ControlLabel>Last name</Form.ControlLabel>
        <Form.Control name="familyName" type="text" />
      </Form.Group>
      <Form.Group controlId="email">
        <Form.ControlLabel>Email</Form.ControlLabel>
        <Form.Control name="email" type="email" />
      </Form.Group>
      <Form.Group controlId="phone">
        <Form.ControlLabel>Phone</Form.ControlLabel>
        <Form.Control name="phone" type="phone" disabled />
        <Form.HelpText>Your confirmed phone number cannot be updated at this time</Form.HelpText>
      </Form.Group>
    </ValidatedForm>
  )
}

export {
  UserDetailsForm
}
