import React, { ReactElement, useEffect } from 'react'

import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { Panel, Loader } from 'rsuite'

import {
  useApi, useApiGroup, useFormState, usePageTitle
} from '../../app/hooks'
import { getGateConfig } from '../../services/graphql/queries'
import { LocationGateForm } from '../../forms/LocationGateForm'
import { PanelHeader } from '../../components'
import { createGate, updateGate } from '../../services/graphql/queries/gates'
import { CreateGateParams } from '../../types/gate'
import { NotFound } from '../error-pages/NotFound'

/**
 * Component to create Gate
 * @returns {ReactElement}
 */
function LocationGateDetails () : ReactElement {
  const navigate = useNavigate()
  const { locationId, gateId } = useParams()
  const api = useApiGroup({
    fetch: useApi(getGateConfig),
    create: useApi(createGate),
    update: useApi(updateGate),
  })

  const fetchResponse = api.requests.fetch.getResponse()
  const saveResponse = gateId
    ? api.requests.update.getResponse()
    : api.requests.create.getResponse()

  const initialValues = gateId ? fetchResponse.data : { name: '' }
  const [formData, setField] = useFormState(initialValues)

  useEffect(() => {
    if (gateId) {
      api.requests.fetch.sendRequest({ gateId })
    }
    return api.cleanup
  }, [])

  const pageTitle = gateId ? 'Edit gate' : 'New gate'
  usePageTitle(pageTitle)

  if (fetchResponse.success && !fetchResponse.data) {
    return <NotFound />
  }

  if (saveResponse.success) {
    return <Navigate to={`/admin/locations/${locationId}`} />
  }

  /**
   * Create or update a gate using the API
   */
  const save = () => {
    const gate = { ...formData, location: locationId } as CreateGateParams
    if (gateId) {
      api.requests.update.sendRequest({ gateId, ...gate })
    } else {
      api.requests.create.sendRequest(gate)
    }
  }

  /**
   * Return to the location list screen
   */
  const cancel = () => {
    navigate(`/admin/locations/${locationId}`)
  }

  const formId = 'gate-details-form'

  return (
    <Panel
      header={(
        <PanelHeader
          loading={saveResponse.loading}
          editing
          formId={formId}
          onCancel={cancel}
          header={pageTitle}
        />
      )}
    >
      {api.requests.fetch.getResponse().loading
        ? <Loader center content="Loading..." />
        : (
          <LocationGateForm
            formId={formId}
            formValue={formData}
            onChange={setField}
            onSubmit={save}
            error={saveResponse.error}
            mode={gateId ? 'edit' : 'new'}
          />
        )}
    </Panel>
  )
}

export {
  LocationGateDetails
}
